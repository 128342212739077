
.sub-visual {
    padding-top: 60px;
    ::v-deep {
        .tit-wrap {
            margin-bottom: 26px;
        }
        .tit {
            text-transform: uppercase;
        }
    }
    &__img {
        height: 180px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        padding-top: 80px;
        ::v-deep {
            .tit-wrap {
                margin-bottom: 30px;
            }
        }
        &__img {
            height: 240px;
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        padding-top: 120px;
        &__img {
            height: 300px;
        }
    }
}
@media (min-width: 1200px) {
}

.sub-visual--only-title {
    ::v-deep {
        .tit-wrap {
            margin-bottom: 0;
            & + .v-tabs {
                margin-top: 26px;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual--only-title {
        ::v-deep {
            .tit-wrap + .v-tabs {
                margin-top: 30px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
